import { FC } from 'react';
import { COUNTRIES } from 'components/Layout/Footer/FeaturesConfigurations/features-countries';

const CountriesList: FC = () => {
  // The component is always hidden, because it's only used for SEO purposes
  return (
    <div className="hidden">
      {COUNTRIES.map(({ country, link, title }) => (
        <a
          key={country}
          href={link}
          title={title}
        />
      ))}
    </div>
  );
};

export default CountriesList;
