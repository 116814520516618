import { ZDTProduct } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import React, { FC } from 'react';
import SkeletonItem from 'components/skeleton/SkeletonItem';
import { MAX_SHOWN_SIZES } from 'constants/sizes';

interface Props {
  product: ZDTProduct.Product;
}

const SizeButtonsLoader: FC<Props> = ({ product }) => {
  const simples = product.Simples || [];

  return (
    <div>
      <SkeletonItem
        width="w-full"
        height="h-5"
        className="mb-6"
      />
      <div className="flex flex-wrap gap-3 tablet:gap-4">
        {simples.map((simple, i) => {
          return (
            <div
              key={i}
              className={clsx(
                'flex h-[30px] min-w-[50px] justify-center rounded-lg border bg-grey-20 p-1',
                {
                  'hidden tablet:block': i >= MAX_SHOWN_SIZES,
                },
              )}
            >
              {/* Using text to set width */}
              <span className="opacity-0">{simple.Size}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SizeButtonsLoader;
