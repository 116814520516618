import { Country } from '.';

export const COUNTRIES: Readonly<Country[]> = [
  {
    country: 'sg',
    imageUrl: '/static-assets/images/countries/icon_sg_flag.svg',
    link: 'https://www.zalora.sg',
    title: 'zalora.sg',
  },
  {
    country: 'my',
    imageUrl: '/static-assets/images/countries/icon_my_flag.svg',
    link: 'https://www.zalora.com.my',
    title: 'zalora.com.my',
  },
  {
    country: 'hk',
    imageUrl: '/static-assets/images/countries/icon_hk_flag.svg',
    link: 'https://www.zalora.com.hk',
    title: 'zalora.com.hk',
  },
  {
    country: 'ph',
    imageUrl: '/static-assets/images/countries/icon_ph_flag.svg',
    link: 'https://www.zalora.com.ph',
    title: 'zalora.com.ph',
  },
  {
    country: 'id',
    imageUrl: '/static-assets/images/countries/icon_id_flag.svg',
    link: 'https://www.zalora.co.id',
    title: 'zalora.co.id',
  },
  {
    country: 'mo',
    imageUrl: '/static-assets/images/countries/icon_mo_flag.svg',
    link: 'https://www.zalora.com.hk',
    title: 'zalora.com.hk',
  },
  {
    country: 'tw',
    imageUrl: '/static-assets/images/countries/icon_tw_flag.svg',
    link: 'https://www.zalora.com.tw',
    title: 'zalora.com.tw',
  },
];
